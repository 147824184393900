<template>
  <el-row :gutter="0">
    <el-col :span="12">
      <el-progress
        :text-inside="true"
        :stroke-width="20"
        :percentage="percentage"
        :color="customColors"
      ></el-progress>
    </el-col>
  </el-row>
</template>

<script>
import { Active } from "@/api/user";

export default {
  name: "Active",
  data() {
    return {
      minutes: 0, //分
      seconds: 10, //秒
      percentage: 0,
      customColor: "#409eff",
      customColors: [
        { color: "#f56c6c", percentage: 20 },
        { color: "#e6a23c", percentage: 40 },
        { color: "#5cb87a", percentage: 60 },
        { color: "#1989fa", percentage: 80 },
        { color: "#6f7ad3", percentage: 100 },
      ],
    };
  },
  created() {
    this.Active();
  },
  methods: {
    Active() {
      let values = {};
      values["active_code"] = this.$route.query.active_code;
      Active(values)
        .then((res) => {
          this.$store.commit("setActive", res);
          console.log(this.$store.getters.getActive);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    increase() {
      this.percentage += 10;
      if (this.percentage > 100) {
        this.percentage = 100;
      }
    },
    decrease() {
      this.percentage -= 10;
      if (this.percentage < 0) {
        this.percentage = 0;
      }
    },
    // 倒计时
    num(n) {
      // 倒计时结束重新刷新页面
      if (this.seconds === 2) {
        console.log("=====本人認証成功！=====");
        this.$message.warning("本人認証成功！");
      }
      if (this.seconds === 0) {
        window.location.href = "about:blank";
        window.close();
      }
      return n < 10 ? "0" + n : "" + n;
    },
    // 倒计时
    timer() {
      var _this = this;
      var time = window.setInterval(function () {
        if (_this.seconds === 0) {
          _this.seconds = 0;
          window.clearInterval(time);
        } else {
          _this.seconds -= 1;
          _this.percentage += 10;
        }
      }, 1000);
    },
  },
  mounted() {
    // 倒计时
    this.timer();
  },
  watch: {
    // 倒计时
    second: {
      handler(newVal) {
        this.num(newVal);
      },
    },
  },
  computed: {
    // 倒计时
    second: function () {
      return this.num(this.seconds);
    },
  },
};
</script>

<style scoped>
</style>
